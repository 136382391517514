import React from 'react';

const PlaneSVG = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 10H4L6.5 5.78947H9.25C9.65 5.78947 10 5.42105 10 5C10 4.57895 9.65 4.21053 9.25 4.21053H6.5L4 0L3 0L4.25 4.21053L1.5 4.21053L0.75 3.15789H0L0.5 5L0 6.84211H0.75L1.5 5.78947L4.25 5.78947L3 10Z"
      fill="black"
    />
  </svg>
);

export default PlaneSVG;
