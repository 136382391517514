import React from 'react';

const FacetimeIconGray = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="11.5" r="11.5" fill="#EEEEEF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 8C6.44772 8 6 8.44772 6 9V15C6 15.5523 6.44772 16 7 16H14C14.5523 16 15 15.5523 15 15V9C15 8.44772 14.5523 8 14 8H7ZM18.5833 9.43332C18.5833 9.24223 18.4744 9.06785 18.3027 8.98401C18.131 8.90018 17.9265 8.92154 17.7758 9.03907L16.1092 10.3391C15.9877 10.4338 15.9167 10.5793 15.9167 10.7333V13.3333C15.9167 13.4625 15.9667 13.5867 16.0563 13.6799L17.7229 15.4132C17.8643 15.5603 18.0809 15.6067 18.2701 15.5304C18.4594 15.4542 18.5833 15.2707 18.5833 15.0666V9.43332Z"
      fill="#3478F6"
    />
  </svg>
);

export default FacetimeIconGray;
