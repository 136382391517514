import React, { useEffect, useState, useRef } from 'react';
// @ts-ignore
import cheveronSVG from './svgs/cheveron-left.svg';
// @ts-ignore
import loadingTextMP4 from './loading-text.mp4';
// @ts-ignore
import bubbleTailSVG from './svgs/bubble-tail.svg';
import { IndexProps } from '../../templates/index-page';
import { StatusBar } from '../contact';
import './styles.css';

interface Props {
  messages: any[];
  contactEmail?: IndexProps['markdownRemark']['frontmatter']['contactEmail'];
  contactPhone?: IndexProps['markdownRemark']['frontmatter']['contactPhone'];
  onClickContact?: () => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  delay?: number;
}

const IMessage = (props: Props) => {
  const DURATION_BETWEEN_MESSAGES = 2000;
  const [timeCounter, setTimeCounter] = useState(
    props.delay ? -props.delay : 0
  );
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const { messages } = props;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [timeCounter]);

  // runs every DURATION_BETWEEEN_MESSAGES to update the time
  useEffect(() => {
    let messagePacingCounter = timeCounter;
    const frame = () => {
      if (messagePacingCounter >= messages.length * DURATION_BETWEEN_MESSAGES) {
        clearInterval(id);
      } else {
        messagePacingCounter += DURATION_BETWEEN_MESSAGES;
        setTimeCounter(messagePacingCounter);
      }
    };
    let id = setInterval(frame, DURATION_BETWEEN_MESSAGES);
    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.clearInterval(id);
      }
    };
  }, []);

  return (
    <div
      id="imessage"
      style={{
        width: '252px',
        height: '411px',
        maxHeight: '100%',
        backgroundColor: 'white',
        overflow: 'hidden',
        ...props.style,
      }}
    >
      {/* Top of the menu */}
      <div className="top-menu">
        <StatusBar />
        <div className="messages">
          <img src={cheveronSVG} alt="cheveron" className="cheveron" />
          <div className="messages-text">Messages</div>
        </div>
        <div className="name-text">marta</div>
        <a
          onClick={props.onClickContact}
          className="contact-button no-js-contact-button"
        >
          Contact
        </a>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `
          <style type="text/css">
            .no-js-contact-button {
              display: none !important;
            }
          </style>
          <a class="contact-button" href="mailto:${props.contactEmail}">
            Contact
          </a>
        `,
          }}
        ></noscript>
      </div>

      {/* start of text body */}

      <div className="text-body">
        {messages.map((message: any, idx: number) => (
          <div
            key={idx}
            style={{ position: 'relative' }}
            className="text-messages"
          >
            <TextMessages
              message={message}
              timerEnd={(idx + 1) * DURATION_BETWEEN_MESSAGES}
              currTime={timeCounter}
            />
          </div>
        ))}
        {/* when timer gets to the last msg, the loading text disappears */}
        {!(timeCounter >= messages.length * DURATION_BETWEEN_MESSAGES) ? (
          <div>
            <video autoPlay loop muted playsInline className="loading-text">
              <source src={loadingTextMP4} type="video/mp4" />
            </video>
          </div>
        ) : null}
        <div ref={messagesEndRef} />
      </div>
      {props.children}
    </div>
  );
};

const TextMessages = (props: {
  message: any;
  timerEnd: number;
  currTime: number;
}) => {
  const { currTime, timerEnd } = props;
  const timeUp = currTime >= timerEnd;

  // When the timer is up, display the text message
  return (
    <React.Fragment>
      <div
        style={{
          display: timeUp ? 'inline-block' : 'none',
          paddingRight: timeUp ? '9px' : '0px',
        }}
        className="text-message"
      >
        <div className="text">{props.message}</div>
        <img src={bubbleTailSVG} alt="bubbleTail" className="bubbleTail" />
      </div>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <style type="text/css">
            .text-message {
              display: inline-block !important;
              padding-right: 9px !important;
            }
            .loading-text {
              display: none !important;
            }
          </style>
        `,
        }}
      ></noscript>
    </React.Fragment>
  );
};

export default IMessage;
