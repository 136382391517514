import React from 'react';

const ContactMessageIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="11.5" r="11.5" fill="#3478F6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 16C14.5376 16 17 13.9853 17 11.5C17 9.01472 14.5376 7 11.5 7C8.46243 7 6 9.01472 6 11.5C6 13.042 6.94797 14.4029 8.39311 15.2138L8.32405 15.3519L8.25 15.5L7.875 16.25L7.5 17V17.5H8.5L8.97018 17.2649L9.5 17L9.81476 16.8426L10.5 16.5L11.5 16Z"
      fill="white"
    />
  </svg>
);

export default ContactMessageIcon;
