import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../site-meta-data';
import { withPrefix } from 'gatsby';

// @ts-ignore
import CloudSprinklesGIF from '../floating-cloud/cloud-sprinkles.gif';
// @ts-ignore
import BickhamScriptPro from '../../fonts/BickhamScriptPro-Regular.woff2';
import Twitter from '../SEO/twitter';

const Layout = ({ children }: any) => {
  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image-am.png`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <Twitter
          title={description}
          image={`${withPrefix('/')}img/og-image-am.png`}
          desc={description}
          username={'@airmilkshake'}
        />

        <link rel="preload" as="image" href={CloudSprinklesGIF} />
        <link rel="preload" as="font" href={BickhamScriptPro} />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
