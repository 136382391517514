import React from 'react';

const BatterySVG = () => (
  <svg
    width="19"
    height="8"
    viewBox="0 0 19 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="16.5" y="2.5" width="1.7" height="3" rx="0.85" fill="#020202" />
    <g>
      <rect x="0.5" y="0.5" width="12" height="7" fill="black" />
    </g>
    <g>
      <rect
        x="0.4"
        y="0.4"
        width="16.2"
        height="7.2"
        rx="0.5"
        stroke="#646464"
        strokeOpacity="0.8"
        strokeWidth="0.8"
      />
    </g>
    <defs>
      <filter
        id="filter0_b"
        x="-3.5"
        y="-3.5"
        width="20"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i"
        x="0"
        y="0"
        width="17"
        height="12"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
);

export default BatterySVG;
