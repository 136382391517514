import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

interface Props {
  className?: string;
  alt?: string;
  image: any;
  objectFit?: string;
}

const PreviewCompatibleImage = ({
  className = '',
  alt = '',
  image,
  objectFit = 'contain',
}: Props) => {
  if (image.childImageSharp) {
    image = image.childImageSharp;
  }
  if (image.fluid.src.includes('blob')) {
    return (
      <div className={className}>
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: '50% 50%',
            opacity: '1',
            transition: 'opacity 500ms ease 0s',
            fontFamily: '"object-fit: contain; object-position: 50% 50%"',
          }}
          src={image.fluid.src}
          alt={alt}
        />
      </div>
    );
  } else {
    return (
      <Img
        className={className}
        // @ts-ignore
        objectFit={objectFit}
        fluid={image.fluid}
        alt={alt}
      />
    );
  }
};

export default PreviewCompatibleImage;
