import React from 'react';

const ContactEmailIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="11.5" r="11.5" fill="#3478F6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00604 7.88944L8.72665 10.7514L5 14.8467V8C5 7.96263 5.00205 7.92574 5.00604 7.88944ZM5.59876 7.08375C5.72158 7.02989 5.8573 7 6 7H17C17.0978 7 17.1924 7.01405 17.2818 7.04025L13.0571 10.29L11.412 11.5555L9.76685 10.29L5.59876 7.08375ZM17.9734 7.76987L14.0973 10.7514L17.9992 15.0393C17.9997 15.0262 18 15.0131 18 15V8C18 7.9208 17.9908 7.84375 17.9734 7.76987ZM17.4323 15.902L13.302 11.3632L11.7168 12.5826C11.5371 12.7208 11.2869 12.7208 11.1071 12.5826L9.52199 11.3632L5.45151 15.8363C5.609 15.9398 5.79747 16 6 16H17C17.1548 16 17.3014 15.9648 17.4323 15.902Z"
      fill="white"
    />
  </svg>
);

export default ContactEmailIcon;
