import React from 'react';

//@ts-ignore
import BackgroundMP4S from '../../img/background-image-s.mp4';
//@ts-ignore
import BackgroundMP4L from '../../img/background-image-l.mp4';
//@ts-ignore
import BackgroundMP4XS from '../../img/background-image-xs.mp4';
//@ts-ignore
import BackgroundWEBMS from '../../img/background-image-s.webm';
//@ts-ignore
import BackgroundWEBML from '../../img/background-image-l.webm';
//@ts-ignore
import BackgroundWEBMXS from '../../img/background-image-xs.webm';

const BackgroundVideo = () => {
  return (
    <video
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'fill',
        position: 'fixed',
        zIndex: -1,
      }}
      autoPlay
      loop
      muted
      playsInline
    >
      <source
        src={BackgroundWEBMS}
        type="video/webm"
        media={`all and (min-width: 1024px)`}
      />
      <source
        src={BackgroundWEBML}
        type="video/webm"
        media={`all and (min-width: 500px) and (max-width: 1023px)`}
      />
      <source
        src={BackgroundWEBMXS}
        type="video/webm"
        media={`all and (max-width: 500px)`}
      />
      <source
        src={BackgroundMP4S}
        type="video/mp4"
        media={`all and (min-width: 1024px)`}
      />
      <source
        src={BackgroundMP4L}
        type="video/mp4"
        media={`all and (min-width: 500px) and (max-width: 1023px)`}
      />
      <source
        src={BackgroundMP4XS}
        type="video/mp4"
        media={`all and (max-width: 500px)`}
      />
    </video>
  );
};

export default BackgroundVideo;
