import React from 'react';

const CheveronLeftSVG = () => (
  <svg
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.41422 0L1.41422 7L0 8.41421L8 16.4142L9.41422 15L2.82843 8.41421L9.82843 1.41421L8.41422 0Z"
      fill="#3178F6"
    />
  </svg>
);

export default CheveronLeftSVG;
