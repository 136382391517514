import React from 'react';

const GradientCircle = () => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27.5" cy="27.5" r="27.5" fill="url(#paint0_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="27.5"
        y1="0"
        x2="27.5"
        y2="55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A5ABB7" />
        <stop offset="1" stopColor="#858992" />
      </linearGradient>
    </defs>
  </svg>
);

export default GradientCircle;
