import React from 'react';
import { ChildImageSharp } from '../../templates/index-page';
import PreviewCompatibleImage from '../preview-compatible-image';
import './styles.css';

interface Props {
  image: ChildImageSharp;
  alt: string;
  style?: React.CSSProperties;
}

const Logo = (props: Props) => {
  return (
    <a href="/#" style={props.style}>
      <PreviewCompatibleImage
        className="logo-container"
        objectFit="contain"
        image={props.image}
        alt={props.alt}
      />
    </a>
  );
};

export default Logo;
