import React, { useState, useEffect } from 'react';
import { IndexProps } from '../../templates/index-page';
import BatterySVG from './svgs/battery.svg';
import PlaneSVG from './svgs/plane.svg';
import CheveronLeftSVG from './svgs/cheveron-left.svg';
import GradientCircle from './svgs/gradient-circle.svg';
import ContactMessageIcon from './svgs/contact-message-icon.svg';
import ContactEmailIcon from './svgs/contact-email-icon.svg';
import FacetimeIconGray from './svgs/contact-facetime-icon-gray.svg';
import PhoneIconGray from './svgs/contact-phone-icon-gray.svg';

import './styles.css';

interface Props {
  contactEmail: IndexProps['markdownRemark']['frontmatter']['contactEmail'];
  contactPhone: IndexProps['markdownRemark']['frontmatter']['contactPhone'];

  onClickMessages: () => void;
}

export const StatusBar = () => {
  const getFormattedTime = () => {
    const timeString = new Date().toLocaleTimeString();
    const timeParts = timeString.split(' ');
    const time = timeParts[0].substr(0, timeParts[0].length - 3);
    return time + ' ' + timeParts[1];
  };
  const [time, setTime] = useState(getFormattedTime());
  useEffect(() => {
    let id = 0;
    if (typeof window !== 'undefined') {
      id = window.setInterval(() => {
        setTime(getFormattedTime());
      }, 1000);
    }

    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.clearInterval(id);
      }
    };
  });
  return (
    <div
      id="contact-header"
      style={{
        width: '100%',
        height: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          marginLeft: '5px',
          width: '100px',
        }}
      >
        <PlaneSVG />
      </div>
      <div
        id="contact-time"
        style={{
          fontFamily: 'SF Pro Display, Inter, sans-serif',
          fontWeight: 'bold',
          fontSize: '9px',
          lineHeight: '11px',
          width: '100px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {getFormattedTime()}
      </div>
      <div
        id="system-battery"
        style={{
          marginRight: '3px',
          display: 'flex',
          width: '100px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontFamily: 'SF Pro Display, Inter, sans-serif',
            fontSize: '9px',
            marginTop: '1px',
            marginRight: '1.5px',
          }}
        >
          75%
        </span>
        <BatterySVG />
      </div>
    </div>
  );
};

const ContactNavPanel = (props: { onClickMessages: () => void }) => (
  <div
    id="contact-navigation-panel"
    style={{
      height: '16.5px',
      marginTop: '12px',
      marginLeft: '10px',
      marginRight: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CheveronLeftSVG />
      <a
        style={{
          cursor: 'pointer',
          fontFamily: 'SF Pro Display, Inter, sans-serif',
          fontSize: '12px',
          lineHeight: '14px',
          color: '#3474F6',
        }}
        onClick={() => props.onClickMessages()}
      >
        Messages
      </a>
    </div>
    <span
      style={{
        fontFamily: 'SF Pro Display, Inter, sans-serif',
        fontSize: '12px',
        lineHeight: '14px',
        color: '#3474F6',
      }}
    >
      Edit
    </span>
  </div>
);

const IPhoneAvatarGroup = () => (
  <div
    id="iphone-contact-header"
    style={{
      display: 'flex',
      justifyContent: 'center',
      marginTop: '12px',
    }}
  >
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <GradientCircle />
      <span
        style={{
          fontFamily: 'SF Pro Display, Inter, sans-serif',
          fontSize: '22px',
          lineHeight: '26px',
          position: 'absolute',
          top: '18.64%',
          left: '34.91%',
          color: 'white',
        }}
      >
        m
      </span>
      <span
        style={{
          fontFamily: 'SF Pro Display, Inter, sans-serif',
          fontSize: '18px',
          lineHeight: '21px',
        }}
      >
        marta
      </span>
    </div>
  </div>
);

const ContactIconPanel = (props: {
  contactEmail: string;
  contactPhone: string;
}) => (
  <div
    id="contact-action-icons"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50px',
      borderBottom: '1px solid #D5D5D5',
    }}
  >
    {[
      {
        icon: <ContactMessageIcon />,
        text: 'message',
        href: `sms://+${props.contactPhone}`,
      },
      {
        icon: <ContactEmailIcon />,
        text: 'email',
        href: `mailto:${props.contactEmail}`,
      },
    ].map((ic, i) => (
      <a
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '7px 9px',
        }}
        href={ic.href}
      >
        {ic.icon}
        <span
          style={{
            fontFamily: 'SF Pro Display, Inter, sans-serif',
            fontSize: '7px',
            lineHeight: '8px',
            color: '#4A88F7',
            marginTop: '5px',
          }}
        >
          {ic.text}
        </span>
      </a>
    ))}
  </div>
);

const ContactInfoSection = (props: { children: React.ReactNode }) => (
  <div
    style={{
      padding: '5px 0 0 12px',
    }}
  >
    <div
      style={{
        borderBottom: '1px solid #D5D5D5',
      }}
    >
      {props.children}
    </div>
  </div>
);

const Contact = (props: Props) => {
  function formatPhone(numbers: string, fmtString: string) {
    let retString = fmtString;
    numbers
      .split('')
      .forEach((n: string) => (retString = retString.replace('d', n)));
    return retString;
  }
  return (
    <div
      id="contact"
      style={{
        overflowY: 'auto',
        width: '252px',
        height: '411px',
        maxHeight: '100%',
        backgroundColor: 'white',
      }}
    >
      <StatusBar />
      <ContactNavPanel onClickMessages={props.onClickMessages} />
      <IPhoneAvatarGroup />
      <ContactIconPanel
        contactEmail={props.contactEmail}
        contactPhone={props.contactPhone}
      />
      <ContactInfoSection>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              fontFamily: 'SF Pro Display, Inter, sans-serif',
              fontSize: '10px',
              lineHeight: '12px',
              marginBottom: '3px',
            }}
          >
            phone
          </span>
          <span
            style={{
              fontFamily: 'SF Pro Display, Inter, sans-serif',
              fontSize: '11px',
              lineHeight: '13px',
              marginBottom: '7px',
              color: '#3478F6',
            }}
          >
            <a
              href={`tel:${formatPhone(props.contactPhone, '+d-ddd-ddd-dddd')}`}
            >
              {formatPhone(props.contactPhone, '+d (ddd) ddd-dddd')}
            </a>
          </span>
        </div>
      </ContactInfoSection>
      <ContactInfoSection>
        <div
          style={{
            display: 'flex',
            height: '35px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              fontFamily: 'SF Pro Display, Inter, sans-serif',
              fontSize: '11px',
              lineHeight: '13px',
            }}
          >
            Facetime
          </span>
          <div style={{ display: 'flex', marginRight: '11px' }}>
            {[<FacetimeIconGray />, <PhoneIconGray />].map((ic, i) => (
              <div key={i} style={{ margin: '0 5px' }}>
                {ic}
              </div>
            ))}
          </div>
        </div>
      </ContactInfoSection>
      <ContactInfoSection>
        <div style={{ height: '55px' }}>
          <span
            style={{
              fontFamily: 'SF Pro Display, Inter, sans-serif',
              fontSize: '10px',
              lineHeight: '12px',
            }}
          >
            Notes
          </span>
        </div>
      </ContactInfoSection>
      {['Send Message', 'Share Contact', 'Add to Favorites'].map((txt, i) => (
        <ContactInfoSection key={i}>
          <div style={{ height: '20px' }}>
            <span
              style={{
                fontFamily: 'SF Pro Display, Inter, sans-serif',
                fontSize: '11px',
                lineHeight: '13px',
                color: '#4E8DF7',
              }}
            >
              {txt}
            </span>
          </div>
        </ContactInfoSection>
      ))}
    </div>
  );
};

export default Contact;
